// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgArrowUpRight = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="m4.86 12.107 8.06-4.654-5.554-1.488a.84.84 0 0 1-.592-1.025.83.83 0 0 1 1.018-.588l7.501 2.01a.83.83 0 0 1 .588 1.018l-2.01 7.502a.83.83 0 1 1-1.605-.43l1.488-5.556-8.061 4.655a.836.836 0 0 1-1.138-.305.836.836 0 0 1 .305-1.139"
    />
  </svg>
);
export const ArrowUpRightIcon = forwardRef(SvgArrowUpRight);
